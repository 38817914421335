import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Grid from '@mui/material/Grid';
import { useGetWorkInfoQuery } from '@app/src/api/profileApi';
import { useEstimateTaxResultsQuery } from '@app/src/api/taxDataApi';

const CurrencyTable = ({ data }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return (
    <>
      {data.map((row, i) => {
        if (row.divider) {
          return <hr key={i} />;
        }

        const { label, value } = row;

        return (
          <Grid container key={label}>
            <Grid item xs={6}>
              {label}
            </Grid>
            <Grid item xs={6}>
              {formatter.format(value)}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

const QueryError = ({ error }) => {
  // FetchBaseQueryError
  if ('status' in error) {
    return (
      <div style={{ margin: '1em' }}>
        An error occured: {error.status} {JSON.stringify(error.data)}
      </div>
    );
  }

  // SerializedError
  return <div style={{ margin: '1em' }}>An error occured: {error.message}</div>;
};

const WorkInfoDetails = ({ workinfo, setWorkinfo, showJSON = false }) => {
  return (
    <div>
      <h3>Work Info</h3>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <label>
          Filing status:
          <select
            style={{
              minWidth: 'unset',
              marginLeft: '0.5em',
              padding: '2px'
            }}
            value={workinfo.status}
            onChange={(e) => {
              if (e.target.value === 'single') {
                setWorkinfo({
                  ...workinfo,
                  status: e.target.value,
                  spouseW2: null,
                  spouse1099: null,
                  creditsFields: {
                    ...workinfo.creditsFields,
                    numDependents: 0
                  }
                });
                return;
              } else if (workinfo.status === 'single' && e.target.value !== 'single') {
                setWorkinfo({
                  ...workinfo,
                  status: e.target.value,
                  spouseW2: 0,
                  spouse1099: 0,
                  creditsFields: {
                    ...workinfo.creditsFields,
                    numDependents: 0
                  }
                });
              } else {
                setWorkinfo({ ...workinfo, status: e.target.value });
              }
            }}
          >
            <option value='single'>Single</option>
            <option value='married'>Married</option>
            <option value='head'>Head of Household</option>
            <option value='married_separate'>Filing separate</option>
          </select>
        </label>
        <label>
          State:
          <input
            style={{
              marginLeft: '0.5em'
            }}
            value={workinfo.state}
            onChange={(e) => setWorkinfo({ ...workinfo, state: e.target.value })}
          />
        </label>
        <label>
          selfW2:
          <input
            style={{
              marginLeft: '0.5em'
            }}
            type='number'
            min='0'
            value={workinfo.selfW2}
            onChange={(e) =>
              setWorkinfo({ ...workinfo, hasW2: Number(e.target.value) > 0, selfW2: Number(e.target.value) })
            }
          />
        </label>
        <label>
          self1099:
          <input
            style={{
              marginLeft: '0.5em'
            }}
            type='number'
            value={workinfo.self1099}
            onChange={(e) => setWorkinfo({ ...workinfo, self1099: Number(e.target.value) })}
          />
        </label>
        {workinfo.status !== 'single' && (
          <label>
            numDependents:
            <input
              style={{
                marginLeft: '0.5em'
              }}
              type='number'
              min='0'
              value={workinfo.creditsFields.numDependents}
              onChange={(e) =>
                setWorkinfo({
                  ...workinfo,
                  creditsFields: {
                    ...workinfo.creditsFields,
                    numDependents: Number(e.target.value)
                  }
                })
              }
            />
          </label>
        )}
        {workinfo.status === 'married' && (
          <>
            <label>
              spouseW2:
              <input
                style={{
                  marginLeft: '0.5em'
                }}
                type='number'
                min='0'
                value={workinfo.spouseW2}
                onChange={(e) => setWorkinfo({ ...workinfo, spouseW2: Number(e.target.value) })}
              />
            </label>
            <label>
              spouse1099:
              <input
                style={{
                  marginLeft: '0.5em'
                }}
                type='number'
                min='0'
                value={workinfo.spouse1099}
                onChange={(e) => setWorkinfo({ ...workinfo, spouse1099: Number(e.target.value) })}
              />
            </label>
          </>
        )}
        <label>
          charityAmt:
          <input
            style={{
              marginLeft: '0.5em'
            }}
            type='number'
            min='0'
            value={workinfo.creditsFields.charityAmt}
            onChange={(e) =>
              setWorkinfo({
                ...workinfo,
                creditsFields: { ...workinfo.creditsFields, charityAmt: Number(e.target.value) }
              })
            }
          />
        </label>
        <label>
          iraContrAmt:
          <input
            style={{
              marginLeft: '0.5em'
            }}
            type='number'
            min='0'
            value={workinfo.creditsFields.iraContrAmt}
            onChange={(e) =>
              setWorkinfo({
                ...workinfo,
                creditsFields: { ...workinfo.creditsFields, iraContrAmt: Number(e.target.value) }
              })
            }
          />
        </label>
        <label>
          mortgageIntAmt:
          <input
            style={{
              marginLeft: '0.5em'
            }}
            type='number'
            min='0'
            value={workinfo.creditsFields.mortgageIntAmt}
            onChange={(e) =>
              setWorkinfo({
                ...workinfo,
                creditsFields: { ...workinfo.creditsFields, mortgageIntAmt: Number(e.target.value) }
              })
            }
          />
        </label>
        <label>
          tuitionFeesAmt:
          <input
            style={{
              marginLeft: '0.5em'
            }}
            type='number'
            min='0'
            value={workinfo.creditsFields.tuitionFeesAmt}
            onChange={(e) =>
              setWorkinfo({
                ...workinfo,
                creditsFields: { ...workinfo.creditsFields, tuitionFeesAmt: Number(e.target.value) }
              })
            }
          />
        </label>
      </form>
      {showJSON && <pre>{JSON.stringify(workinfo, null, 2)}</pre>}
    </div>
  );
};

const CSV = ({ results }) => {
  return (
    <div>
      <h3>CSV</h3>
      <pre aria-label='Tax Calculator Results'>
        {[
          'Adjusted Gross Income',
          'Deductions',
          'Standard Deduction',
          'Other Deductions',
          'Taxable Income',
          'Federal Tax Liability',
          'FICA Tax Liability',
          'Total Tax Liability',
          'Credits',
          'Taxes Withheld'
        ].join(',')}
        {'\n'}
        {results
          .map(([workinfo, data]) => {
            return [
              data.agi + data.writeOffDeductions + data.iraDeduction,
              data.writeOffDeductions,
              data.standardDeduction,
              data.otherDeductions,
              data.taxableIncome,
              data.grossTaxes - workinfo.selfW2 * 0.0765,
              data.ficaLiability,
              data.grossTaxes,
              data.totalCredits,
              data.taxesWithheld
            ].join(',');
          })
          .join('\n')}
      </pre>
    </div>
  );
};

const TaxCalculatorCSVRow = ({ workinfo }) => {
  const { data, error, isLoading } = useEstimateTaxResultsQuery(workinfo);

  if (isLoading) {
    return 'Row is loading...';
  }

  if (error) {
    return `Error: ${error.message}`;
  }

  return (
    [
      data.agi + data.writeOffDeductions + data.iraDeduction,
      data.writeOffDeductions,
      data.standardDeduction,
      data.otherDeductions,
      data.taxableIncome,
      data.grossTaxes - workinfo.selfW2 * 0.0765,
      data.ficaLiability,
      data.grossTaxes,
      data.totalCredits,
      data.taxesWithheld
    ]
      .map((value) => _.round(value, 2))
      .join(',') + '\n'
  );
};

const BulkTaxCalculatorResultsCSV = ({ data }) => {
  const [formData, setFormData] = useState(data);

  return (
    <div
      style={{
        paddingBottom: '1em'
      }}
    >
      <h3>Bulk Tax Results CSV</h3>
      {formData.map((workinfo, i) => {
        return (
          <React.Fragment key={i}>
            <WorkInfoDetails
              workinfo={workinfo}
              setWorkinfo={(newWorkinfo) => {
                setFormData([...formData.slice(0, i), newWorkinfo, ...formData.slice(i + 1)]);
              }}
            />
            <button onClick={() => setFormData([...formData.slice(0, i), ...formData.slice(i + 1)])}>Remove row</button>
          </React.Fragment>
        );
      })}
      <br />
      <button
        onClick={() =>
          setFormData([
            ...formData,
            {
              ...(formData[formData.length - 1]
                ? formData[formData.length - 1]
                : {
                    status: 'single',
                    state: 'CA',
                    hasW2: false,
                    selfW2: 50000,
                    self1099: 0,
                    spouseW2: 0,
                    spouse1099: 0,
                    creditsFields: {}
                  })
            }
          ])
        }
      >
        Add row
      </button>
      <pre>
        {[
          'Adjusted Gross Income',
          'Deductions',
          'Standard Deduction',
          'Other Deductions',
          'Taxable Income',
          'Federal Tax Liability',
          'FICA Tax Liability',
          'Total Tax Liability',
          'Credits',
          'Taxes Withheld'
        ].join(',')}
        {'\n'}
        {formData.map((workinfo, i) => (
          <TaxCalculatorCSVRow key={i} workinfo={workinfo} />
        ))}
      </pre>
    </div>
  );
};

const TaxCalculatorResults = ({ workinfo }) => {
  const { data, error, isLoading } = useEstimateTaxResultsQuery(workinfo);

  if (isLoading) {
    return <div style={{ margin: '1em' }}>Loading tax calculator results...</div>;
  }

  if (error) {
    return <QueryError error={error} />;
  }

  const totalEstimatedTaxBill = (data.netBalance ?? 0) + (data.stateNetBalance ?? 0);
  const originalTaxBill = totalEstimatedTaxBill + data.taxSavings;

  return (
    <div>
      <CSV results={[[workinfo, data]]} />
      <h3>Estimated Tax Results</h3>
      <CurrencyTable
        data={[
          {
            label: totalEstimatedTaxBill > 0 ? 'Estimated tax bill' : 'Estimated tax refund',
            value: Math.abs(totalEstimatedTaxBill)
          },
          {
            label: originalTaxBill > 0 ? 'Original tax bill' : 'Original tax refund',
            value: Math.abs(originalTaxBill)
          },
          { label: 'Savings from deductions', value: data.taxSavings }
        ]}
      />
      <h3>Federal</h3>
      <CurrencyTable
        data={[
          { label: 'Adjusted gross income', value: data.agi + data.writeOffDeductions + data.iraDeduction },
          { label: 'Deductions', value: data.writeOffDeductions },
          { label: 'Standard deduction', value: data.standardDeduction },
          { label: 'Other deductions', value: data.otherDeductions },
          { divider: true },
          { label: 'Taxable income', value: data.taxableIncome },
          { label: 'Federal tax liability', value: data.grossTaxes },
          { label: 'Credits', value: data.totalCredits },
          { label: 'Taxes withheld', value: data.taxesWithheld },
          { divider: true },
          {
            label: data.netBalance > 0 ? 'Est. federal tax bill' : 'Est. federal tax refund',
            value: Math.abs(data.netBalance)
          }
        ]}
      />
      {!!data.filingState && (
        <>
          <h3>{data.filingState}</h3>
          <CurrencyTable
            data={[
              { label: `${data.filingState} tax liability`, value: data.stateTaxLiability },
              { label: `${data.filingState} taxes withheld`, value: data.stateTaxesWithheld },
              {
                label:
                  data.stateNetBalance > 0
                    ? `Est. ${data.filingState} tax bill`
                    : `Est. ${data.filingState} tax refund`,
                value: Math.abs(data.stateNetBalance)
              }
            ]}
          />
        </>
      )}
      <h3>Raw data</h3>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
};

const TaxCalculator = () => {
  const { data, error, isLoading } = useGetWorkInfoQuery();
  const [workinfo, setWorkinfo] = useState(null);

  useEffect(() => {
    if (isLoading || error) {
      return;
    }

    setWorkinfo({
      status: data.filing_status ?? 'single',
      state: data.state_residence,
      hasW2: data.w2_income ?? false,
      selfW2: data.w2_income_amount ?? 0,
      self1099: data.agi ?? 0,
      spouseW2: data.spouse_w2_income ?? 0,
      spouse1099: data.spouse_agi ?? 0,
      creditsFields: data.tax_calc_credits ?? {}
    });
  }, [data, error, isLoading]);

  if (isLoading) {
    return <div>Loading work info...</div>;
  }

  if (error) {
    return <QueryError error={error} />;
  }

  if (workinfo === null) {
    return <div>Waiting for work info...</div>;
  }

  return (
    <div style={{ margin: '1em' }}>
      <WorkInfoDetails workinfo={workinfo} setWorkinfo={setWorkinfo} showJSON />
      {(() => {
        if (isLoading) {
          return <div>Waiting for work info...</div>;
        }

        if (error) {
          return <QueryError error={error} />;
        }

        return <TaxCalculatorResults workinfo={workinfo} />;
      })()}
      <BulkTaxCalculatorResultsCSV
        data={[10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000]
          .map((selfW2) => ({
            status: 'single',
            state: 'CA',
            hasW2: true,
            selfW2,
            self1099: 0,
            spouseW2: 0,
            spouse1099: 0,
            creditsFields: {}
          }))
          .concat(
            [10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000].map((self1099) => ({
              status: 'single',
              state: 'CA',
              hasW2: false,
              selfW2: 0,
              self1099,
              spouseW2: 0,
              spouse1099: 0,
              creditsFields: {}
            }))
          )
          .concat(
            [25000, 50000, 75000, 100000].flatMap((selfW2) =>
              [25000, 50000, 75000, 100000].flatMap((self1099) => [
                {
                  status: 'single',
                  state: 'CA',
                  hasW2: true,
                  selfW2,
                  self1099,
                  spouseW2: 0,
                  spouse1099: 0,
                  creditsFields: {}
                }
              ])
            )
          )}
      />
    </div>
  );
};

export default TaxCalculator;
